var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ToastMessage, toastSuccess, toastError } from '@samc/react-ui-core';
import { Grid } from '@samc/react-ui-grid';
import { addField, ApiContext, FieldStatus, SqlTypes, updateField, } from '@samc/user-defined-fields-api';
import AddRowButton from '@samc/react-ui-grid/lib/atoms/AddRowButton/AddRowButton';
import './CustomFieldGrid.css';
import { CustomFieldEditorFlyout } from '../CustomFieldEditorFlyout/CustomFieldEditorFlyout';
import { sqlTypes } from '../../constants/SqlTypes';
import { StatusValueGetter } from './StatusValueGetter/StatusValueGetter';
const noop = () => undefined;
export const valueGetter = (params) => { var _a, _b; return (_b = (_a = sqlTypes.find((i) => i.value === params.data.type)) === null || _a === void 0 ? void 0 : _a.display) !== null && _b !== void 0 ? _b : ''; };
const udfIdentifier = 'C_';
export const CustomFieldGrid = (props) => {
    const { udfTableId, customFields, onAddField, onEditField, onGridReady, onReload } = props;
    const newField = useMemo(() => {
        return {
            id: uuidv4(),
            name: udfIdentifier,
            type: SqlTypes.nvarchar,
            tableId: udfTableId,
            description: '',
            reasonForCreation: '',
            status: FieldStatus.Pending,
        };
    }, [udfTableId, customFields]);
    const [targetField, setTargetField] = useState(newField);
    const [editorOpen, setEditorOpen] = useState(false);
    const APIRef = useRef({ redrawRows: () => '', deselectAll: () => '' });
    const api = React.useContext(ApiContext);
    const handleSave = (updatedField) => __awaiter(void 0, void 0, void 0, function* () {
        let response;
        let handler;
        if (updatedField.id === newField.id) {
            response = yield addField(api.UdfApi, updatedField, api.requestInit);
            handler = onAddField;
        }
        else {
            response = yield updateField(api.UdfApi, updatedField, api.requestInit);
            handler = onEditField;
        }
        if (response.error) {
            toastError(React.createElement(ToastMessage, { title: "Error", message: response.error }));
        }
        else {
            toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Field Saved Successfully" }));
            setEditorOpen(false);
            handler(updatedField);
        }
        APIRef.current.deselectAll();
    });
    const caseInsensitiveComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    const fields = React.useMemo(() => [
        {
            headerName: 'Name',
            field: 'name',
            editableRule: 'false',
            link: ({ node }) => {
                setTargetField(node.data);
                setEditorOpen(true);
            },
            sort: 'asc',
            comparator: caseInsensitiveComparator,
        },
        {
            headerName: 'Status',
            field: 'status',
            editableRule: 'false',
            width: 100,
            valueGetter: StatusValueGetter,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: Object.values(FieldStatus).filter((v) => Number.isNaN(Number(v))),
            },
        },
        {
            headerName: 'Description',
            field: 'description',
            editableRule: 'false',
            comparator: caseInsensitiveComparator,
        },
        {
            headerName: 'Type',
            field: 'type',
            editableRule: 'false',
            width: 100,
            valueGetter,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: sqlTypes.map((t) => t.display),
            },
            floatingFilter: true,
        },
    ], []);
    const AddButton = React.useCallback(() => (React.createElement(AddRowButton, { onClick: () => {
            setTargetField(newField);
            setEditorOpen(true);
        } })), [newField]);
    React.useEffect(() => {
        APIRef.current.redrawRows();
    }, [customFields]);
    return (React.createElement("div", { className: "custom-field-page-grid" },
        customFields && (React.createElement(Grid, { onSubmit: noop, fields: fields, data: customFields, suppressEditToggle: true, suppressAggregateToggle: true, suppressDeleteButton: true, suppressRowSelector: true, sizeColumnsToFit: true, renderControlBarItems: AddButton, onBodyGridReady: (params) => {
                APIRef.current = params.api;
                if (onGridReady)
                    onGridReady(params);
            }, onToggleRefresh: onReload, getContextMenuItems: (params) => {
                var _a;
                const field = (_a = params.node) === null || _a === void 0 ? void 0 : _a.data;
                if (!field)
                    return [];
                return [
                    {
                        name: 'Sync',
                        action: () => handleSave(field),
                    },
                ];
            } })),
        targetField && (React.createElement(CustomFieldEditorFlyout, { isNew: targetField.id === newField.id, isOpen: editorOpen, customField: targetField, onClose: () => {
                setEditorOpen(false);
                APIRef.current.deselectAll();
            }, onSave: handleSave, udfIdentifier: udfIdentifier }))));
};
export default CustomFieldGrid;
