import React from 'react';
import { EditingProvider } from '@samc/react-ui-grid';
import { CustomFieldPage } from '@samc/user-defined-fields-core';
import { HeaderContextProvider, RoutingWrapper, useHeaderContext } from '@samc/react-ui-history';
import { useCurrentUser, Entitlements } from '@samc/single-spa-authentication';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';

const DefaultSelectedTableId = 'Asset';

export const CustomFieldsMain = (): JSX.Element => {
    const { setValue: setHeaderValue, tabs } = useHeaderContext();

    const firstTabId = tabs?.at(0)?.id;
    React.useEffect(() => {
        setHeaderValue('selectedTabId', firstTabId);
    }, [firstTabId, setHeaderValue]);

    return (
        <EditingProvider>
            <RoutingWrapper>
                <CustomFieldPage defaultSelectedTableId={DefaultSelectedTableId} />
            </RoutingWrapper>
        </EditingProvider>
    );
};

export const CustomFieldsPage = (): JSX.Element => {
    const user = useCurrentUser();

    if (!user.isLoaded) return <div />;

    if (!user.hasEntitlement(Entitlements.ConfigurationManagement.ManageCustomFields))
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                title="You are not authorized to view this page"
                text="If you believe this is an error, please contact your administrator"
            />
        );

    return (
        <HeaderContextProvider>
            <CustomFieldsMain />
        </HeaderContextProvider>
    );
};

export default CustomFieldsPage;
