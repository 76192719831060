var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mergeHeaders } from '@samc/react-ui-request';
import { GetHeaders } from './GetHeaders';
export const getUserDefinedFields = (url, tableId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!tableId)
        return [];
    const response = yield fetch(`${url}/api/v1/field/table/${tableId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getUDFTables = (url, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(`${url}/api/v1/tables`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const addField = (url, field, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(`${url}/api/v1/field`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(field),
    });
    return response.json();
});
export const updateField = (url, field, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield fetch(`${url}/api/v1/field/${field.id}`, {
            method: 'PUT',
            headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
            body: JSON.stringify(field),
        });
    }
    catch (_e) {
        return {
            status: 'ERROR',
            error: 'Update failed',
            errorCount: 1,
        };
    }
    return {
        status: 'SUCCESS',
    };
});
