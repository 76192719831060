var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { ApiContext } from '../../../ApiContext';
import { getUserDefinedFields } from '../../Requests';
export const ASSETFIELD_KEY = 'customassetfields';
export const useUserDefinedFields = (udfTableId) => {
    const api = React.useContext(ApiContext);
    const query = (url, tableId) => __awaiter(void 0, void 0, void 0, function* () {
        return getUserDefinedFields(url, tableId, api.requestInit);
    });
    return useRequestBase({
        key: ASSETFIELD_KEY,
        query,
        requestContext: ApiContext,
        params: [api.UdfApi, udfTableId],
        options: {
            staleTime: 10 * 60 * 1000,
        },
    });
};
