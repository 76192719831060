export var SqlTypes;
(function (SqlTypes) {
    SqlTypes[SqlTypes["int"] = 0] = "int";
    SqlTypes[SqlTypes["decimal"] = 1] = "decimal";
    SqlTypes[SqlTypes["float"] = 2] = "float";
    SqlTypes[SqlTypes["date"] = 3] = "date";
    SqlTypes[SqlTypes["datetime"] = 4] = "datetime";
    SqlTypes[SqlTypes["nvarchar"] = 5] = "nvarchar";
})(SqlTypes || (SqlTypes = {}));
export default SqlTypes;
