import { FieldStatus } from '@samc/user-defined-fields-api';
export const StatusValueGetter = (params) => {
    const { data, colDef } = params;
    const { field } = colDef;
    if (!field)
        return '';
    const value = data[field];
    const setting = FieldStatus[value];
    return setting;
};
export default StatusValueGetter;
