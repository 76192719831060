import React, { useState, useMemo, useEffect } from 'react';
import { useUDFTables, useUserDefinedFields } from '@samc/user-defined-fields-api';
import { Spinner, SpinnerSize, initializeIcons } from '@fluentui/react';
import './CustomFieldPage.css';
import { SearchableDropdown } from '@samc/react-ui-core';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { CustomFieldGrid } from '../../molecules/CustomFieldGrid/CustomFieldGrid';
initializeIcons();
export const CustomFieldPage = (props) => {
    const { defaultSelectedTableId } = props;
    const loadedTables = useUDFTables();
    const tables = useMemo(() => {
        return loadedTables.data || [];
    }, [loadedTables.data]);
    const [selectedTable, setSelectedTable] = useState(tables.find((t) => t.id === defaultSelectedTableId));
    const fieldResult = useUserDefinedFields(selectedTable === null || selectedTable === void 0 ? void 0 : selectedTable.id);
    const [assetFields, setAssetFields] = useState([]);
    const { setValue: setHeaderValue } = useHeaderContext();
    const tableOptions = useMemo(() => {
        if (!loadedTables || !loadedTables.data)
            return [];
        return (loadedTables.data.map((t) => {
            return { id: t.id, displayText: t.name };
        }) || []);
    }, [loadedTables]);
    React.useEffect(() => {
        setHeaderValue('pageTitle', React.createElement(SearchableDropdown, { placeholder: "Select a Table", className: "custom-field-page-table-selector", options: tableOptions, onChange: (option) => {
                setSelectedTable(tables === null || tables === void 0 ? void 0 : tables.find((t) => t.id === option.id));
            }, selectedKeys: (selectedTable === null || selectedTable === void 0 ? void 0 : selectedTable.id) ? [selectedTable === null || selectedTable === void 0 ? void 0 : selectedTable.id] : undefined }));
    }, [selectedTable, setHeaderValue, tableOptions, tables]);
    React.useEffect(() => {
        setHeaderValue('tabs', [{ header: 'Custom Fields', id: 'custom-fields' }]);
    }, [setHeaderValue]);
    useEffect(() => {
        setAssetFields(fieldResult.data || []);
    }, [fieldResult]);
    const handleAddField = (field) => {
        setAssetFields((f) => [...f, field]);
    };
    const handleEditField = (field) => {
        setAssetFields((fields) => fields.map((f) => {
            if (f.id === field.id)
                return field;
            return f;
        }));
    };
    return (React.createElement("div", { className: "custom-field-page-container" },
        React.createElement("div", { className: "custom-field-page-body" },
            selectedTable && (React.createElement(CustomFieldGrid, { key: "custom-field-grid", udfTableId: selectedTable.id, customFields: assetFields, onAddField: handleAddField, onEditField: handleEditField, onReload: fieldResult.refresh })),
            !selectedTable && (React.createElement("div", { className: "custom-field-page-select-table-prompt" },
                React.createElement(Spinner, { size: SpinnerSize.large }),
                " ",
                React.createElement("span", null, "Select a Table..."))))));
};
export default CustomFieldPage;
