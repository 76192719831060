// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-4b77e648bf/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-4b77e648bf/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-field-page-table-selector {
    align-self: flex-start;
    width: 300px !important;
}

.custom-field-page-header {
    display: flex;
    flex-direction: column;
}

.custom-field-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.custom-field-page-select-table-prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-weight: bold;
}

.custom-field-page-select-table-prompt > * {
    padding: 3px;
}

.custom-field-page-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-user-defined-fields-core-virtual-a575ec10fb/1/packages/core/lib/organisms/CustomFieldPage/CustomFieldPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".custom-field-page-table-selector {\n    align-self: flex-start;\n    width: 300px !important;\n}\n\n.custom-field-page-header {\n    display: flex;\n    flex-direction: column;\n}\n\n.custom-field-page-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 100%;\n    width: 100%;\n}\n\n.custom-field-page-select-table-prompt {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 300px;\n    font-weight: bold;\n}\n\n.custom-field-page-select-table-prompt > * {\n    padding: 3px;\n}\n\n.custom-field-page-body {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
