import React from 'react';
import FormEditorFlyout from '@samc/react-ui-core/lib/atoms/FormEditorFlyout/FormEditorFlyout';
import { DataEntryForm, InputType, LayoutType } from '@samc/react-ui-form';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { sqlTypes } from '../../constants/SqlTypes';
export const CustomFieldEditorFlyout = (props) => {
    const { isOpen, onClose, onSave, customField, isNew, udfIdentifier } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const form = React.useMemo(() => ({
        title: 'Custom Field',
        fields: {
            name: {
                inputType: InputType.Text,
                editableRule: isNew ? 'true' : 'false',
                field: 'name',
                displayNameRule: "'Name'",
                requiredFieldRule: 'true',
                defaultValueRule: `'${udfIdentifier}'`,
            },
            tableId: {
                inputType: InputType.Text,
                editableRule: 'true',
                field: 'tableId',
                displayNameRule: "'Table ID'",
            },
            id: {
                inputType: InputType.Text,
                editableRule: 'true',
                field: 'id',
                displayNameRule: "'ID'",
            },
            type: {
                inputType: InputType.Select,
                field: 'type',
                requiredFieldRule: 'true',
                displayNameRule: "'Type'",
                menuItems: sqlTypes,
                displayProperty: 'display',
                valueProperty: 'value',
                editableRule: isNew ? 'true' : 'false',
            },
            precision: {
                inputType: InputType.Number,
                displayFormat: '#',
                field: 'precision',
                requiredFieldRule: 'IIF([type] = 1, true, false)',
                displayNameRule: "'Precision'",
                editableRule: isNew ? 'true' : 'false',
                visibleRule: 'IIF([type] = 1, true, false)',
                defaultValueRule: '38',
            },
            scale: {
                inputType: InputType.Number,
                displayFormat: '#',
                field: 'scale',
                requiredFieldRule: 'IIF([type] = 1, true, false)',
                displayNameRule: "'Scale'",
                editableRule: isNew ? 'true' : 'false',
                visibleRule: 'IIF([type] = 1, true, false)',
                defaultValueRule: '6',
            },
            description: {
                inputType: InputType.MultilineText,
                displayNameRule: "'Description'",
                field: 'description',
                requiredFieldRule: 'true',
                editableRule: 'true',
            },
            reasonForCreation: {
                inputType: InputType.MultilineText,
                field: 'reasonForCreation',
                displayNameRule: "'Reason for Creation'",
                requiredFieldRule: 'true',
                editableRule: 'true',
            },
            status: {
                field: 'status',
                inputType: InputType.Text,
                visibleRule: 'false',
            },
        },
        tabs: [
            {
                title: '',
                sections: [
                    {
                        titleExpression: 'Custom Field',
                        fieldLayout: {
                            field: '',
                            type: LayoutType.VerticalStack,
                            children: [
                                { field: 'name', type: LayoutType.HorizontalStack, children: [] },
                                { field: 'type', type: LayoutType.HorizontalStack, children: [] },
                                { field: 'precision', type: LayoutType.HorizontalStack, children: [] },
                                { field: 'scale', type: LayoutType.HorizontalStack, children: [] },
                                { field: 'description', type: LayoutType.HorizontalStack, children: [] },
                                { field: 'reasonForCreation', type: LayoutType.HorizontalStack, children: [] },
                            ],
                        },
                    },
                ],
                layout: {
                    type: LayoutType.VerticalStack,
                    index: 0,
                    children: [
                        {
                            children: [],
                            index: 0,
                            type: LayoutType.VerticalStack,
                        },
                    ],
                },
            },
        ],
        gridFields: [],
    }), [isNew, udfIdentifier]);
    const handleSubmit = (values) => {
        const field = Object.assign({}, values);
        if (field.name.toUpperCase().indexOf(udfIdentifier) !== 0) {
            field.name = `${udfIdentifier}${field.name}`;
        }
        return onSave(field).then(() => {
            setIsLoading(false);
        });
    };
    const formApi = React.useRef();
    const onRejection = React.useCallback(() => {
        var _a;
        (_a = formApi.current) === null || _a === void 0 ? void 0 : _a.resetForm();
        onClose();
    }, [onClose]);
    const onSubmit = React.useCallback(() => {
        var _a;
        (_a = formApi.current) === null || _a === void 0 ? void 0 : _a.submitForm();
        setIsLoading(true);
    }, []);
    return (React.createElement("div", null,
        React.createElement(FormEditorFlyout, { isOpen: isOpen, title: "Edit Custom Field", rejectionText: "Cancel", confirmationText: "Save", width: 600, onRejection: onRejection, onConfirmation: onSubmit, className: "custom-field-editor-flyout-container" },
            isLoading && React.createElement(Spinner, { size: SpinnerSize.large, styles: { root: { margin: 10 } } }),
            React.createElement(DataEntryForm, { formConfig: form, initialData: customField, onSubmit: handleSubmit, onFormLoaded: (_formApi) => {
                    formApi.current = _formApi;
                } }))));
};
export default CustomFieldEditorFlyout;
