/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Router as CustomRouter } from 'react-router-dom';
import { ApiContext } from '@samc/user-defined-fields-api';
import { Client as Styletron } from 'styletron-engine-atomic';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toastError, ToastMessageContainer, LayerWrapper } from '@samc/react-ui-core';
import { ThemeProvider, defaultTheme } from '@samc/react-ui-theme';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { BrowserHistory, HashHistory } from 'history';
import { HistoryProvider } from '@samc/react-ui-history';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { CustomFieldsPage } from './pages/UserDefinedFields';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    styletronEngine: Styletron;
    browserHistory: BrowserHistory;
    hashHistory: HashHistory;
}

const queryClient = new QueryClient();

const Wrapper = (props: Props): React.ReactElement | null => {
    const { browserHistory, hashHistory } = props;

    const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
    React.useEffect(() => {
        const timer = setTimeout(() => setGracePeriodActive(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    const tenantState = useTenantState();
    const tenantName = tenantState?.name;

    const authState = useUserAuthenticationState();
    const isUserAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
    const token = authState?.accessToken?.value;

    const [location, setLocation] = React.useState(hashHistory.location);

    React.useEffect(() => {
        return hashHistory.listen((l) => setLocation(l.location));
    }, [hashHistory]);

    const { result: clientConfiguration } = useClientConfiguration();

    if (clientConfiguration === undefined)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>Could not load authorization configuration</MessageBar>
        );

    if (!isUserAuthenticated)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>
                User is unauthorized - Please log out and back in again
            </MessageBar>
        );

    if (!tenantName)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>
                No client selected - Please select a client and try again
            </MessageBar>
        );

    const headers = {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenantName,
    };

    return (
        <AuthorizationWrapper
            config={{
                ...clientConfiguration,
                defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
            }}
            jwt={token}
        >
            <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
                <LayerWrapper>
                    <HistoryProvider value={{ browserHistory, hashHistory }}>
                        <ThemeProvider style={{ width: '100%', height: '100%' }} value={defaultTheme}>
                            <QueryClientProvider client={queryClient}>
                                <ApiContext.Provider
                                    value={{
                                        requestInit: {
                                            headers,
                                        },
                                        UdfApi: '/user-defined-fields',
                                        ShowMessage: toastError,
                                    }}
                                >
                                    <ToastMessageContainer
                                        position="top-center"
                                        draggable
                                        pauseOnHover
                                        pauseOnFocusLoss
                                        autoClose={4000}
                                        style={{ zIndex: 1000001 }}
                                    />
                                    <CustomRouter location={location} navigator={hashHistory}>
                                        <CustomFieldsPage />
                                    </CustomRouter>
                                </ApiContext.Provider>
                            </QueryClientProvider>
                        </ThemeProvider>
                    </HistoryProvider>
                </LayerWrapper>
            </AgGridLicenseProvider>
        </AuthorizationWrapper>
    );
};

export default Wrapper;
