// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-4b77e648bf/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-4b77e648bf/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-field-page-grid {
    display: flex;
    flex-grow: 1;
    min-height: 200px;
    justify-content: stretch;
    padding: 8px;
}

.custom-field-page-grid .ag-cell .ag-checkbox,
.custom-field-page-grid .ag-header-cell .ag-checkbox {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-user-defined-fields-core-virtual-a575ec10fb/1/packages/core/lib/molecules/CustomFieldGrid/CustomFieldGrid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,wBAAwB;IACxB,YAAY;AAChB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":[".custom-field-page-grid {\n    display: flex;\n    flex-grow: 1;\n    min-height: 200px;\n    justify-content: stretch;\n    padding: 8px;\n}\n\n.custom-field-page-grid .ag-cell .ag-checkbox,\n.custom-field-page-grid .ag-header-cell .ag-checkbox {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
