export const sqlTypes = [
    {
        display: 'int',
        value: 0,
    },
    {
        display: 'decimal',
        value: 1,
    },
    {
        display: 'float',
        value: 2,
    },
    {
        display: 'date',
        value: 3,
    },
    {
        display: 'datetime',
        value: 4,
    },
    {
        display: 'nvarchar',
        value: 5,
    },
];
export default sqlTypes;
