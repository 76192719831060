/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@samc/react-ui-core';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const lifecycles = singleSpaReact({
    renderType: 'createRoot',
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
